import { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { BlockUI } from 'primereact/blockui';

import ContentResponsive from '../Layout/ContentResponsive';

import { useSettings } from '../../context/settings-context';
import { getLocaleOptionInFunction, Locales, useLocale } from '../../context/locale-context';

import { Dropdown, InputContainer, InputErrorMessage, InputLabel } from '.';
import { CheckedElement, File as FileInterface, UserFile } from '../../shared/interfaces';

import { API_URL } from '../../shared/Constants';

import { getToken, Token } from '../../shared/helpers/localStorage';

export interface FileComponentInterface {
  options: CheckedElement[];
  file: File | null;
}

interface CustomFileUploadProps {
  label?: string;
  error?: boolean;
  onUpload: (files: UserFile[]) => void;
  uploadDisabled?: boolean;
  customUpload?: (files: UserFile[]) => void;
}

export const uploadFileHandler = async (files: File[]) => {
  const data = new FormData();
  for (let x = 0; x < files.length; x++) {
    data.append('multiPartFiles', files[x]);
  }

  const token = getToken(Token.ACCESS_TOKEN);

  const sendFile = await fetch(`${API_URL}/files/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      // 'Content-Type' : 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });

  const json: any = await sendFile.json();

  return json;
};

// this component will upload files to api endpoint and return array of uploaded files back
export const CustomFileUpload = ({
  label,
  error,
  onUpload,
  uploadDisabled,
  customUpload,
}: CustomFileUploadProps) => {
  const { showToast } = useSettings();
  const { getLocaleOption } = useLocale();

  const defaultFilesObject = useMemo(() => {
    return {
      options: [
        {
          name: 'referralFromGynecologist',
          title: getLocaleOption('referralFromGynecologist'),
          checked: false,
        },
        {
          name: 'referralFromUrologist',
          title: getLocaleOption('referralFromUrologist'),
          checked: false,
        },
        {
          name: 'bloodTestHormoneAnalysis',
          title: getLocaleOption('bloodTestHormoneAnalysis'),
          checked: false,
        },
        {
          name: 'bloodTestSerology',
          title: getLocaleOption('bloodTestSerology'),
          checked: false,
        },
        {
          name: 'ultrasound',
          title: getLocaleOption('ultrasound'),
          checked: false,
        },
        {
          name: 'previousOperationsStomach',
          title: getLocaleOption('previousOperationsStomach'),
          checked: false,
        },
        {
          name: 'previousFertilityTreatments',
          title: getLocaleOption('previousFertilityTreatments'),
          checked: false,
        },
        {
          name: 'psychotherapeuticTreatment',
          title: getLocaleOption('psychotherapeuticTreatment'),
          checked: false,
        },
        {
          name: 'fallopianTubePatencyTest',
          title: getLocaleOption('fallopianTubePatencyTest'),
          checked: false,
        },
        {
          name: 'laparoscopy',
          title: getLocaleOption('laparoscopy'),
          checked: false,
        },
        {
          name: 'uterineEndoscopy',
          title: getLocaleOption('uterineEndoscopy'),
          checked: false,
        },
        {
          name: 'chromosomeAnalysis',
          title: getLocaleOption('chromosomeAnalysis'),
          checked: false,
        },
        {
          name: 'miscarriage',
          title: getLocaleOption('miscarriage'),
          checked: false,
        },
        {
          name: 'spermAnalysis',
          title: getLocaleOption('spermAnalysis'),
          checked: false,
        },
        {
          name: 'cycleLogs',
          title: getLocaleOption('cycleLogs'),
          checked: false,
        },
        {
          name: 'otherFindings',
          title: getLocaleOption('otherFindings'),
          checked: false,
        },
      ],
      file: null,
    };
  }, [getLocaleOption]);

  const [files, setFiles] = useState<FileComponentInterface[]>([defaultFilesObject]);

  useEffect(() => {
    setFiles([defaultFilesObject]);
  }, [defaultFilesObject]);

  const updateFilesHandler = (
    index: number,
    updatedOptions: CheckedElement[],
    uploadedFile?: File
  ) => {
    const stateFiles = [...files];

    let error = false;

    const map = stateFiles.map((fileObj, i) => {
      if (uploadedFile) {
        // files with duplicate names are not allowed
        const find = stateFiles
          .filter((fileObj) => fileObj.file)
          .find((fileObj) => fileObj.file!.name === uploadedFile.name);
        if (find) error = true;
      }
      return {
        ...fileObj,
        file: uploadedFile ? (index === i ? uploadedFile : fileObj.file) : fileObj.file,
        options: index === i ? updatedOptions : fileObj.options,
      };
    });

    if (error) {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('sameNameFilesNotAllowed'),
      });
    } else {
      setFiles(map);
    }
  };

  const removeFileHandler = (index: number) => {
    const stateFiles = [...files];
    if (files.length === 1) {
      const map = stateFiles.map((fileObject, i) => {
        return {
          ...fileObject,
          file: null,
        };
      });
      setFiles(map);
    } else {
      const filter = stateFiles.filter((file, i) => i !== index);
      setFiles(filter);
    }
  };

  const uploadHandler = async () => {
    const filter = [...files].filter((fileOjb) => fileOjb.file); // we first get only objects that have files in them

    const getFiles = filter.map((fileObject) => {
      return fileObject.file!;
    });

    const json = await uploadFileHandler(getFiles); // upload files to server

    if (!json.items) {
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: json.message,
      });
    }

    // for each file find its name in original files state and prepare new object for upload
    const makeFilesArray: UserFile[] = json.items.map((file: FileInterface) => {
      const { uuid, uploadOriginalFilename } = file;

      const find = filter.find(
        (fileObj) => fileObj.file!.name === uploadOriginalFilename
      );

      const getOptionValue = find!.options.find((option) => option.checked)!.name;

      return {
        value: uuid,
        name: getOptionValue,
        title: getLocaleOptionInFunction(getOptionValue!.toString(), Locales.DE),
      };
    });

    if (customUpload) return customUpload(makeFilesArray);

    if (makeFilesArray.length === 0) {
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: getLocaleOption('noFileSelected'),
      });
    }

    onUpload(makeFilesArray);

    showToast({
      type: 'success',
      title: getLocaleOption('success'),
      text: getLocaleOption('filesUploaded'),
    });

    setFiles([defaultFilesObject]);
  };

  return (
    <InputContainer>
      <InputLabel error={error}>{label}</InputLabel>
      <div className='p-fileupload p-fileupload-advanced p-component'>
        <div className='p-fileupload-buttonbar'>
          {files.length > 0 && (
            <Button
              label={getLocaleOption('upload')}
              onClick={uploadHandler}
              disabled={uploadDisabled}
            />
          )}
          {files.length > 0 && (
            <Button
              label={getLocaleOption('clear')}
              onClick={() => setFiles([defaultFilesObject])}
            />
          )}
        </div>
        <div className='p-fileupload-content'>
          {files.map((fileObject, index) => (
            <FileComponent
              key={index}
              fileObject={fileObject}
              removeFile={() => removeFileHandler(index)}
              updateFiles={(options, file) => updateFilesHandler(index, options, file)}
            />
          ))}
          <div className='flex justify-content-end'>
            <Button
              icon='pi pi-plus'
              className='px-3'
              style={{ width: 'auto' }}
              onClick={() => setFiles([...files, defaultFilesObject])}
            />
          </div>
        </div>
      </div>
      {error && <InputErrorMessage />}
    </InputContainer>
  );
};

interface FileComponentProps {
  fileObject: FileComponentInterface;
  removeFile: () => void;
  updateFiles: (updatedOptions: CheckedElement[], uploadedFile?: File) => void;
}

const FileComponent = ({ fileObject, removeFile, updateFiles }: FileComponentProps) => {
  const { getLocaleOption } = useLocale();

  const { file, options } = fileObject;

  const selectedOption = options.find((option) => option.checked);

  let preview;
  if (file?.type.includes('image')) {
    preview = <img src={URL.createObjectURL(file)} alt='' width={50} />;
  }

  const inputRef = useRef<any>(null);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      updateFiles([...options], file);
    }
  };

  return (
    <BlockUI blocked={false}>
      <div className='grid align-items-center'>
        <input
          type='file'
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={changeHandler}
        />
        <ContentResponsive className='col-3'>
          <Dropdown
            label={getLocaleOption('selectDocument')}
            options={options}
            setOptions={(options) => updateFiles(options as CheckedElement[])}
            noGutter
          />
        </ContentResponsive>
        <ContentResponsive className='col-9'>
          {file ? (
            <div className='flex justify-content-between align-items-center relative'>
              {preview}
              <div className='p-fileupload-filename'>{file.name}</div>
              <div>{file.size / 1000} KB</div>
              <div className='flex'>
                <Button
                  icon='pi pi-times'
                  className='p-button-rounded p-button-danger p-button-outlined'
                  aria-label='Cancel'
                  style={{ width: '3rem', height: '3rem' }}
                  onClick={removeFile}
                />
              </div>
            </div>
          ) : (
            selectedOption && (
              <div className='flex justify-content-end'>
                <Button
                  label={getLocaleOption('choose')}
                  icon='pi pi-plus'
                  onClick={() => inputRef.current.click()}
                />
              </div>
            )
          )}
        </ContentResponsive>
      </div>
    </BlockUI>
  );
};
