interface UserDataItemProps {
  title: string;
  value: string;
}

export const UserDataItem = ({ title, value }: UserDataItemProps) => (
  <div className='flex'>
    <p className='text-lg mr-2'>{title}: </p>
    <p className='text-lg font-semibold'>{value}</p>
  </div>
);
