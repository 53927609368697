import { useState, useEffect, PropsWithChildren } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useAPI } from 'context/api-context';

import { Button } from 'primereact/button';

import LocaleSelector from 'components/Layout/LocaleSelector';

import fertillyLogo from 'assets/logo.svg';

import { ClinicLogoOptionCodes, LogoResponse } from 'shared/interfaces';

interface AuthProps {
  title?: string;
}

const Auth = ({ children, title = '' }: PropsWithChildren<AuthProps>) => {
  const { fetchAPI } = useAPI();
  const [searchParams] = useSearchParams();

  const logoParam = searchParams.get('logo') || localStorage.getItem('logo');
  const idParam = searchParams.get('id') || localStorage.getItem('id');

  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    if (logoParam === ClinicLogoOptionCodes.none) {
      return;
    }

    async function fetchClinicLogo() {
      const clinicLogo: LogoResponse = await fetchAPI(`/clinics/${idParam}/logofile/`, {
        withToken: false,
      });

      if (clinicLogo?.data) {
        const result = `data:image/png;base64,${clinicLogo.data}`;
        setLogo(result);
      } else {
        setLogo(fertillyLogo);
      }
    }

    if (logoParam === ClinicLogoOptionCodes.clinic && idParam) {
      fetchClinicLogo();
      return;
    }

    setLogo(fertillyLogo);
  }, [fetchAPI, idParam, logoParam]);

  return (
    <div className='w-full h-screen relative'>
      <div className='auth-responsive'>
        {logo && <img width={250} src={logo} alt='logo' />}
        <LocaleSelector />
      </div>
      <div className='auth-main'>
        <div className='absolute right-0 top-0 z-5 pt-5 pr-5'>
          <LocaleSelector />
        </div>
        <div className='block absolute pt-5 w-full'>
          <div className='block mx-auto' style={{ width: '250px' }}>
            {logo && <img width={250} src={logo} alt='logo' />}
          </div>
        </div>
      </div>
      <div className='flex' style={{ height: `100%` }}>
        <div className='relative block m-auto w-full' style={{ maxWidth: '1000px' }}>
          <h2 className='text-4xl text-center mb-6 font-bold'>{title}</h2>
          {children}
        </div>
      </div>
    </div>
  );
};

export const AuthContainer: React.FC = ({ children }) => (
  <div className='grid justify-content-center align-items-center'>
    <div className='field w-full'>{children}</div>
  </div>
);

export const FormContainer: React.FC = ({ children }) => (
  <div className='mb-2 block mx-auto auth-form-container'>{children}</div>
);

interface AuthLinkProps {
  to: string;
  text?: string;
}

export const AuthLink = ({
  children,
  to,
  text,
}: React.PropsWithChildren<AuthLinkProps>) => (
  <p className='mb-1 text-center flex justify-content-center'>
    {text && <span className='mr-1'>{text}</span>}
    <Link to={to} className='block text-primary font-bold text-lg'>
      {children}
    </Link>
  </p>
);

interface AuthMessageProps {
  error?: boolean;
}

export const AuthMessage = ({
  children,
  error,
}: React.PropsWithChildren<AuthMessageProps>) => {
  return (
    <p
      className={`py-1 text-center text-xl font-bold ${error ? 'p-invalid' : ''}`}
      style={{ minHeight: '33px' }}
    >
      {children}
    </p>
  );
};

interface AuthButtonProps {
  label: string;
  loading?: boolean;
}

export const AuthButton = ({ label, loading = false }: AuthButtonProps) => (
  <div className='grid mt-3 px-3'>
    <Button
      className='block w-full p-button-raised mb-3'
      label={label}
      loading={loading}
      iconPos='left'
    />
  </div>
);

export default Auth;
