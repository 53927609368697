import { useLocale } from 'context/locale-context';

import { Button } from 'primereact/button';

import ContentResponsive from 'components/Layout/ContentResponsive';
import { Checkbox, Dropdown, Input } from 'components/Inputs';

import { getInputsImmutable } from '../User.helpers';
import { PersonItem } from '../User.types';
import { CheckedElement } from 'shared/interfaces';

interface PersonProps {
  changeInputHandler: (
    value: React.SetStateAction<CheckedElement[]> | React.SetStateAction<string>,
    type: string,
    id: string
  ) => void;
  inputs: PersonItem[];
  clinicOrFertillyUser: boolean;
  checkbox: CheckedElement[];
  setCheckbox: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  agreeError: boolean;
  editProfileHandler: () => void;
  completed: boolean;
}

const Person = ({
  inputs,
  clinicOrFertillyUser,
  changeInputHandler,
  checkbox,
  setCheckbox,
  agreeError,
  editProfileHandler,
  completed,
}: PersonProps) => {
  const { getLocaleOption } = useLocale();

  return (
    <div className='flex flex-column col-12'>
      <div className='grid'>
        <ContentResponsive className='col-9'>
          {getInputsImmutable(inputs, clinicOrFertillyUser).map((input) => {
            const {
              id,
              label,
              type,
              value,
              options,
              error,
              HTMLType,
              inputMask,
              mask,
              filterBy,
              showNumberButtons,
              useNumberGrouping,
              mandatory,
              maxlength,
            } = input;

            const getInput = () => {
              if (type === 'string')
                return (
                  <Input
                    id={id}
                    label={`${label} ${mandatory ? '*' : ''}`}
                    value={value as string}
                    setValue={(value) => changeInputHandler(value, type, id)}
                    error={error}
                    type={HTMLType}
                    inputMask={inputMask}
                    mask={mask}
                    showNumberButtons={showNumberButtons}
                    useNumberGrouping={useNumberGrouping}
                    maxlength={maxlength}
                  />
                );

              if (type === 'dropdown')
                return (
                  <Dropdown
                    label={`${label} ${mandatory ? '*' : ''}`}
                    options={options!}
                    setOptions={(values) => changeInputHandler(values, type, id)}
                    error={error}
                    filterBy={filterBy}
                  />
                );
            };

            return (
              <div className='grid' key={id}>
                <div className='col-12'>{getInput()}</div>
              </div>
            );
          })}
        </ContentResponsive>
      </div>
      <div className='grid person-footer'>
        <div className='col-9'>
          <Checkbox items={checkbox} setItems={setCheckbox} error={agreeError} />
        </div>
        <div className='col-3'>
          <div className='flex justify-content-end'>
            <Button
              label={getLocaleOption(completed ? 'update' : 'continue')}
              onClick={editProfileHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Person;
