import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useClinics } from 'context/clinics-context';
import { useLocale } from 'context/locale-context';
import { useAPI } from 'context/api-context';
import { useSettings } from 'context/settings-context';

import { Button } from 'primereact/button';

import Layout from 'components/Layout/Layout';
import ContentResponsive from 'components/Layout/ContentResponsive';
import { Dropdown, Input } from 'components/Inputs';
import SurveyJSCreatorWidget from './components/SurveyJSCreatorWidget';

import { findCheckedElement, mapClinicsToCheckedElements } from './Questionnaire.utils';
import {
  CheckedElement,
  Questionnaire as QuestionnaireInterface,
  SurveyType,
  ErrorResponse,
} from 'shared/interfaces';

const getSurveyTypes = (): CheckedElement[] => {
  return [
    {
      title: 'MEDICAL',
      name: SurveyType.MEDICAL,
      checked: false,
    },
    {
      title: 'EXPORT AS PDF',
      name: SurveyType.MEDICAL_PDF,
      checked: false,
    },
    {
      title: 'BASE DATA',
      name: SurveyType.BASE_DATA,
      checked: false,
    },
  ];
};

const QuestionnaireBuilder = () => {
  const { clinics } = useClinics();
  const { getLocaleOption } = useLocale();
  const { fetchAPI } = useAPI();
  const { showToast } = useSettings();

  const location = useLocation();
  const state = location.state as QuestionnaireInterface;

  const [edit, setEdit] = useState(!!state?.edit);
  const [questionnaireId, setQuestionnaireId] = useState(state?.id);

  const [name, setName] = useState(state?.name || '');
  const [nameError, setNameError] = useState(false);

  const [personTypeId, setPersonTypeId] = useState(state?.personTypeId || '');

  const [clinicOptions, setClinicOptions] = useState<CheckedElement[]>([]);
  const [clinicOptionsError, setClinicOptionsError] = useState(false);

  const [surveyTypeOptions, setSurveyTypeOptions] = useState<CheckedElement[]>([]);
  const [surveyTypeOptionError, setSurveyTypeOptionError] = useState(false);

  const [surveyData, setSurveyData] = useState<string | null>(null);

  const checkedClinicId = findCheckedElement(clinicOptions);
  const checkedSurveyType = findCheckedElement(surveyTypeOptions);

  useEffect(() => {
    const clinicId = state ? state.clinicId : null;
    const clinicsMap = mapClinicsToCheckedElements(clinics, clinicId);

    setClinicOptions(clinicsMap);
  }, [clinics, state]);

  useEffect(() => {
    if (name) setNameError(false);
    if (clinicOptionsError && checkedClinicId) setClinicOptionsError(false);
  }, [name, checkedClinicId, clinicOptionsError]);

  useEffect(() => {
    setSurveyTypeOptions(getSurveyTypes());
  }, []);

  useEffect(() => {
    if (surveyTypeOptionError && checkedSurveyType) {
      setSurveyTypeOptionError(false);
    }
  }, [surveyTypeOptions, checkedSurveyType, surveyTypeOptionError]);

  async function handleSubmit() {
    if (!name || !checkedClinicId || !checkedSurveyType) {
      setNameError(name === '');
      setClinicOptionsError(checkedClinicId === undefined);
      setSurveyTypeOptionError(checkedSurveyType === undefined);
      return;
    }

    if (!surveyData && !state) {
      return showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: 'Add at least one question!',
        timeout: 5000,
      });
    }

    const body = {
      clinicId: checkedClinicId,
      name,
      personTypeId,
      rowVersion: 0,
      surveyJSJson: JSON.stringify(surveyData),
      surveyType: checkedSurveyType,
    };

    const url = '/surveys/' + (edit && questionnaireId ? questionnaireId + '/' : '');
    const method = edit ? 'PUT' : 'POST';

    const response: QuestionnaireInterface & ErrorResponse = await fetchAPI(url, {
      method: method,
      withToken: true,
      body: JSON.stringify(body),
    });

    if (response.id) {
      showToast({
        type: 'success',
        title: getLocaleOption('success'),
        text: questionnaireId
          ? `${getLocaleOption('edit')} ${getLocaleOption('success')}`
          : getLocaleOption('questionnaireCreated'),
      });

      if (!edit) {
        setQuestionnaireId(response.id);
        setEdit(true);
      }
    } else {
      showToast({
        type: 'error',
        title: getLocaleOption('error'),
        text: response?.message || getLocaleOption('somethingWentWrong'),
      });
    }
  }

  return (
    <Layout>
      <ContentResponsive>
        <Input
          id='questionnaire-name'
          value={name}
          setValue={setName}
          label={getLocaleOption('questionnaireName')}
          noGutter
          error={nameError}
        />
        <div className='flex justify-content-between align-items-center mb-1'>
          <div className='flex lg:flex-grow-1'>
            <div className='col-12 lg:col-3 pl-0'>
              <Dropdown
                label={getLocaleOption('chooseSurveyType')}
                options={surveyTypeOptions}
                setOptions={setSurveyTypeOptions}
              />
            </div>
            <div className='col-12 lg:col-4'>
              <Input
                id='person-type-id'
                value={personTypeId}
                setValue={setPersonTypeId}
                label={getLocaleOption('chooseType')}
              />
            </div>
            <div className='col-12 lg:col-4'>
              <Dropdown
                label={getLocaleOption('chooseClinic')}
                options={clinicOptions}
                setOptions={setClinicOptions}
                error={clinicOptionsError}
              />
            </div>
          </div>
          <Button
            label={getLocaleOption('submit')}
            onClick={handleSubmit}
            className='p-button-lg'
          />
        </div>
        <div className='grid questionnaire'>
          <SurveyJSCreatorWidget setSurveyData={setSurveyData} />
        </div>
      </ContentResponsive>
    </Layout>
  );
};

export default QuestionnaireBuilder;
