import { useState, useEffect, useCallback, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useClinics } from 'context/clinics-context';
import { useLocale } from 'context/locale-context';
import { useAuth } from 'context/auth-context';
import { useSettings } from 'context/settings-context';
import { useAPI } from 'context/api-context';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import Layout from 'components/Layout/Layout';
import InviteClinic from './components/InviteClinic';
import ProtectedConfirmDialog from 'components/ProtectedConfirmDialog';
import ToggleSwitchWithFetching from './components/ToggleSwitchWithFetching';
import ChangeLogoOptions from './components/ChangeLogoOptions';

import makeDate from 'shared/helpers/makeDate';
import { Clinic as ClinicInterface, DeleteResponse } from 'shared/interfaces';
import { downloadBlobURL } from 'shared/helpers/openBlobURL';

const Clinics = () => {
  const navigate = useNavigate();
  const { clinics: initialClinics, getClinics } = useClinics();
  const { checkUserPrivileges } = useAuth();
  const { getLocaleOption, locale } = useLocale();
  const { fetchAPI, fetchFileAPI } = useAPI();
  const { somethingWentWrongToast } = useSettings();

  const [clinics, setClinics] = useState(initialClinics);
  const [fetching, setFetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [deletionConfirmationDialogIsVisible, setDeletionConfirmationDialogIsVisible] =
    useState(false);
  const [clinicIdForDeletion, setClinicIdForDeletion] = useState<number | null>(null);

  const accessInvite = checkUserPrivileges('INVITE_CLINIC_USER');

  const searchHandler = (event: FormEvent<HTMLInputElement>) => {
    setGlobalFilter(event.currentTarget.value);
  };

  const tableHeaderTemplate = (
    <div className='table-header flex align-items-center justify-content-between'>
      <h5 className='mx-0 my-1'>{getLocaleOption('clinics')}</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={searchHandler}
          placeholder={`${getLocaleOption('search')}...`}
        />
      </span>
    </div>
  );

  const editClinicTemplate = (data: ClinicInterface) => (
    <Button
      label={getLocaleOption('edit')}
      onClick={() => navigate('/edit-clinic', { state: data })}
    />
  );

  const messengerTemplate = ({ id, chatEnabled }: ClinicInterface) => (
    <ToggleSwitchWithFetching
      clinicProp='chatEnabled'
      path='chat'
      clinicId={id}
      enabled={chatEnabled}
      clinics={clinics}
      setClinics={setClinics}
    />
  );

  const treatmentPlaceTemplate = ({ id, surveyTreatmentPlaceEnabled }: ClinicInterface) => (
    <ToggleSwitchWithFetching
      clinicProp='surveyTreatmentPlaceEnabled'
      path='treatment_place'
      clinicId={id}
      enabled={surveyTreatmentPlaceEnabled}
      clinics={clinics}
      setClinics={setClinics}
    />
  );

  const infoPageTemplate = ({ id, showInfoPage }: ClinicInterface) => (
    <ToggleSwitchWithFetching
      clinicProp='showInfoPage'
      path='info_page'
      clinicId={id}
      enabled={showInfoPage}
      clinics={clinics}
      setClinics={setClinics}
    />
  );

  const logoTemplate = (clinic: ClinicInterface) => (
    <ChangeLogoOptions clinics={clinics} setClinics={setClinics} clinic={clinic} />
  );

  const deleteClinicHandler = useCallback(
    async (clinicId: number | null) => {
      if (clinicId) {
        setFetching(true);

        const response: DeleteResponse = await fetchAPI(`/clinics/${clinicId}/delete/`, {
          method: 'DELETE',
          withToken: true,
        });

        if (response.deleted) {
          await getClinics();
        } else {
          somethingWentWrongToast();
        }

        setFetching(false);
      }
    },
    [fetchAPI, somethingWentWrongToast, getClinics]
  );

  useEffect(() => {
    if (!deletionConfirmationDialogIsVisible) {
      setClinicIdForDeletion(null);
    }
  }, [deletionConfirmationDialogIsVisible]);

  const deleteConfirmDialog = (clinicId: number) => {
    setDeletionConfirmationDialogIsVisible(true);
    setClinicIdForDeletion(clinicId);
  };

  const deleteClinicTemplate = ({ id }: ClinicInterface) => (
    <Button
      label={getLocaleOption('delete')}
      className='p-button-danger'
      onClick={() => deleteConfirmDialog(id)}
    />
  );

  const handleSubmit = useCallback(async () => {
    setFetching(true);

    const response = await fetchFileAPI(`/statistics/tracking/users/file`, {
      method: 'POST',
      customHeaders: {
        Accept: 'application/octet-stream',
      },
    });

    if (response) {
      let date = new Date();
      downloadBlobURL(response, `${date}.xlsx`);
    } else {
      somethingWentWrongToast();
    }

    setFetching(false);
  }, [fetchFileAPI, somethingWentWrongToast]);

  return (
    <Layout>
      <Button
        label={getLocaleOption('registrationOverview')}
        onClick={handleSubmit}
        className='p-button-lg mb-3'
      />
      <DataTable
        loading={fetching}
        value={clinics}
        responsiveLayout='scroll'
        showGridlines
        header={tableHeaderTemplate}
        globalFilter={globalFilter}
        globalFilterFields={['name']}
        paginator
        paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
        currentPageReportTemplate={getLocaleOption('tableNumberingTemplate')}
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage={getLocaleOption('tableNoAvailableOptions')}
      >
        <Column
          header={getLocaleOption('name')}
          body={(data: ClinicInterface) => data.name}
        />
        <Column
          header={getLocaleOption('registrationDate')}
          body={(data: ClinicInterface) => makeDate(new Date(data.createdOn), locale)}
        />
        <Column header={getLocaleOption('edit')} body={editClinicTemplate} />
        <Column
          header={getLocaleOption('messenger')}
          body={messengerTemplate}
          className='text-center'
        />
        <Column
          header={getLocaleOption('surveyTreatmentPlace')}
          body={treatmentPlaceTemplate}
          className='text-center'
        />
        <Column header='Logo' body={logoTemplate} className='text-center' />
        <Column header='Info page' body={infoPageTemplate} className='text-center' />
        <Column header={getLocaleOption('delete')} body={deleteClinicTemplate} />
        {accessInvite && (
          <Column
            header={getLocaleOption('inviteLink')}
            body={(data: ClinicInterface) => <InviteClinic id={data.id} />}
          />
        )}
      </DataTable>
      <ProtectedConfirmDialog
        visible={deletionConfirmationDialogIsVisible}
        setVisible={setDeletionConfirmationDialogIsVisible}
        messageLocaleOption='confirmDeleteClinic'
        acceptLabelLocaleOption='deleteClinic'
        onAccept={() => deleteClinicHandler(clinicIdForDeletion)}
      />
    </Layout>
  );
};

export default Clinics;
