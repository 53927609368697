import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import { useLocale } from 'context/locale-context';

import { Dialog } from 'primereact/dialog';

import Layout from 'components/Layout/Layout';

import { infoContent } from './infoContent';

type InfoContentItem = typeof infoContent[number];

export const Info = () => {
  const { getLocaleOption } = useLocale();

  const [visible, setVisible] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<InfoContentItem | null>(null);

  const openVideo = (video: InfoContentItem) => {
    setCurrentVideo(video);
    setVisible(true);
  };

  const closeVideo = () => {
    setCurrentVideo(null);
    setVisible(false);
  };

  return (
    <Layout>
      <div className='flex flex-column lg:flex-row space-between flex-wrap p-0'>
        {infoContent.map((infoItem) => (
          <div
            key={infoItem.title}
            className='col-12 lg:col-6 xl:col-4'
            onClick={() => openVideo(infoItem)}
          >
            <div className='info-item flex flex-column pb-5'>
              <div className='relative cursor-pointer'>
                <img
                  src={infoItem.preview}
                  alt={getLocaleOption(infoItem.title)}
                  className='info-item-image'
                  loading='lazy'
                  width={536}
                  height={299}
                />
                <div className='info-item-preview-overlay'>
                  <i
                    className='pi pi-play'
                    style={{ fontSize: '7.5rem', color: 'white' }}
                  />
                </div>
              </div>
              <h3 className='my-3 text-xl lg:text-4xl'>
                {getLocaleOption(infoItem.title)}
              </h3>
              <p className='text-sm lg:text-lg'>
                {getLocaleOption(infoItem.description)}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        header={getLocaleOption(currentVideo?.title || '')}
        visible={visible}
        onHide={closeVideo}
        className='info-dialog'
      >
        {currentVideo && (
          <Vimeo
            video={currentVideo.id}
            autoplay
            loop
            width={window.innerWidth < 1091 ? 308 : 900}
          />
        )}
      </Dialog>
    </Layout>
  );
};
