import { useEffect, useState } from 'react';

import { Button } from 'primereact/button';

import FetchingContainer from './FetchingContainer';

import { UserFile } from '../shared/interfaces';

import { useAPI } from '../context/api-context';
import { useLocale } from '../context/locale-context';

import { downloadBlobURL } from '../shared/helpers/openBlobURL';

interface FilePreviewProps {
  files: UserFile[];
}

const FilePreview = ({ files }: FilePreviewProps) => {
  const { fetchFileAPI } = useAPI();
  const { getLocaleOption } = useLocale();

  interface FileBlob {
    name: string;
    blob: Blob | undefined;
    origin: string;
  }

  const [fetching, setFetching] = useState(false);
  const [fileBlobs, setFileBlobs] = useState<FileBlob[]>([]);

  useEffect(() => {
    if (files.length === 0) return;

    const fetchFiles = async () => {
      setFetching(true);
      const blobList: FileBlob[] = [];

      for (let i = 0; i < files.length; i++) {
        const file: UserFile = files[i];
        const getFile: Blob | undefined = await fetchFileAPI(
          `/files/uuid=${file.value}/data/`,
          {
            customHeaders: {
              Accept: 'application/pdf',
            },
          }
        );

        blobList.push({
          name: file.name,
          blob: getFile,
          origin: file.origin || ''
        });
      }

      setFileBlobs(blobList);
      setFetching(false);
    };

    fetchFiles();
  }, [files, fetchFileAPI]);

  const renderFiles = () => {
    return fileBlobs.map((fileBlob: FileBlob, index) => {
      const { name, blob, origin } = fileBlob;
      const displayedName: string = `${getLocaleOption(name)}`;
      return (
        <Button
          className='block mb-3 p-2 border-round text-xl text-left w-full'
          onClick={() => blob && downloadBlobURL(blob, ``)}
          key={index}
        >
          {displayedName}
        </Button>
      );
    });
  };

  return <FetchingContainer fetching={fetching}>{renderFiles()}</FetchingContainer>;
};

export default FilePreview;
